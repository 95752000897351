.Modal-Notificacion-Carga-Archivo .ant-modal-content .ant-modal-body{
    padding: 0 !important;
    
}
.Modal-Notificacion-Carga-Archivo-Procesando .ant-modal-content .ant-modal-body{
    padding: 0 !important;
    
}
.Modal-Notificacion-Carga-Archivo-Procesando .ant-modal-content{
    
    border-radius: 18px !important;
}
.Modal-Notificacion-Carga-Archivo-Contenedor .ant-modal-body{
    padding: 0 !important;
}
.Modal-Notificacion-Carga-Archivo-Contenedor .ant-modal-content{
    border-radius: 18px !important;
}

.Modal-Cargando-Archivos-Python-Guardando .ant-modal-content .ant-modal-body{
    padding: 20px 10px 10px 10px;
}

.Contenedor-Modal-Notificacion-Carga-Archivo{
    padding: 24px;
    display:'flex';
    justify-content:'center';
}

.Contenedor-Modal-Notificacion-Carga-Archivo .ant-modal-content{
    border-radius: 18px;
}

.Texto-Modal-Notificacion-Carga-Archivo-Procesando{
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    display: flex;
    justify-content: center;
    margin-bottom: 0 !important;
    /* line-height: 20px; */
}
.Texto-Modal-Notificacion-Carga-Archivo{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    display: flex;
    justify-content: center;
}

.Contenedor-Texto-Notificacion-Rango-Tiempo{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.Texto-Modal-Notificacion-Carga-Archivo-Mensaje{
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    display: flex;
    justify-content: center;
}
.Texto-Modal-Notificacion-Carga-Archivo-Horarios{
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    display: flex;
    justify-content: center;
}

.Texto-Modal-Guardando-Archivos-Python{
    font-size: 14px;
    text-align: center;
    color: #000000;
    padding: 0 20px;
    z-index: 10;
}

.Check-Modal-Centrado-Carga-Archivo{

    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.Modal-Cargando-Archivos-Python-Guardando .ant-modal-header{
    border-radius: 20px 20px 0 0 !important;
}
.Modal-Cargando-Archivos-Python-Guardando .ant-modal-content{
    border-radius: 18px !important;
}
.Modal-Cargando-Archivos-Python .ant-modal-header{
    border-radius: 20px 20px 0 0 !important;
}
.Modal-Cargando-Archivos-Python .ant-modal-content{
    border-radius: 18px !important;
}


.Titulo-Modal-Subir-Archivo{
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}
.Contenedor-Imagen-Loading{
    display: flex;
    justify-content: center;
    height: 70px;
}
.Contenedor-Imagen-Procesando{
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    height: 80px;
}

.Imagen-Avion-Loading{
    position: absolute;
    top: 40px !important;
    height: 100%;
    width: auto;
}

.Contenedor-Imagen-Loading > img{
    clip-path: inset(60px 10px 60px 10px);
}
