.Contenedor-Principal-Dts{
    padding: 80px 40px 0px 40px;
    margin-top: 10px;
}

.Contenedor-Centrado{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Imagen-Pais{
    width: 25px;
    height: 25px;
}

.Text-Center-Dts{
    text-align: center;
    margin-bottom: 10px;
}

.Contenedor-Acciones-Dts{
    display: flex;
    justify-content: center
}

.Contenedor-Principal-Dts .ant-table-pagination-right{
    display: none;
}

.Circulo{
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
}

.Circulo-Activo{
    background-color: #1EEC41;
}

.Circulo-Desactivo{
    background-color: #FF3742;
}

.Celda-Tabla-Dts-Distribuidora{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.Text-Center-Dts-Administrador{
    text-align: center;
    text-decoration: underline;
    text-decoration-color: yellow;
    user-select: none;
}

.Input-Editar{
    width: 100% !important;
    margin: 3px !important;
}

.Select-Dts-Administrador .ant-select-selector{
    height: 41px !important;
    margin-top: 2px;
    padding-top: 3px !important;
}

.Contenedor-Principal-Dts table th{
    background-color: #000000 !important;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    padding: 6px !important;
}

.Contenedor-Principal-Dts table th:first-child{
    border-top-left-radius: 8px !important;
}

.Contenedor-Principal-Dts table th:last-child{
    border-top-right-radius: 8px !important;
}

.Boton-Accion{
    margin: 0 1px;
    border-color:#404040 !important;
}
.Icono-Accion{
    color: #404040 !important;
}

.Boton-Accion-Editar{
    margin: 0 1px;
    border-color:#007FFF !important;
}
.Icono-Accion-Editar{
    color: #007FFF !important;
}
.Boton-Accion-Eliminar:hover{
    border-color: #FF3742 !important;
}
.Boton-Accion-Eliminar{
    border-color: #FF3742 !important;
}

.Icono-Accion-Eliminar{
    color: #FF3742 !important;
}

.Boton-Crear-Distribuidora{
    background: #1890ff !important;
    border-color: #1890ff !important;
    color: white !important;
    margin-left: 10px;
    width: 100px;
}