.Enlace-Permisos-Usuario{
    color: #2e5ac9;
}

.Contenedor-Acciones-Iconos{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Boton-Accion-Editar{
    margin: 0 1px !important;
    border-color:#007FFF !important;
}

.Icono-Accion-Editar{
    color: #007FFF !important;
}

.Boton-Accion-Eliminar:hover{
    border-color: #FF3742 !important;
}

.Icono-Accion-Eliminar{
    color: #FF3742 !important;
}

.Contenedor-Tipo-Usuarios table th{
    background-color: #000000 !important;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    padding: 6px !important;
}

.Contenedor-Tipo-Usuarios table th:first-child{
    border-top-left-radius: 8px !important;
}
.Contenedor-Tipo-Usuarios table th:last-child{
    border-top-right-radius: 8px !important;
}

.Contenedor-Tipo-Usuarios .ant-table-pagination-right{
    display: none;
}

.Contenedor-Tipo-Usuarios{
    padding: 80px 40px 0 40px;
    margin-top: 10px;
    display: flex;
    flex-direction: column; 
}

.Text-Center{
    text-align: center;
}

.Contenedor-Fila-Boton-Agregar{
    display: flex;
    justify-content: end;
    align-items: center;
}

.Contenedor-Fila-Buscar-Permiso{
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    align-items: center;
}