.Contenedor-Center{
    display: flex;
    justify-content: center;
}

.Contenedor-Modal-Imagen{
    width: 80%;
}

.Contenedor-Imagen-Actual{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Contenedor-Imagen-Icono-Actual{
    padding: 10px;
}

.Boton-Accion-Imagen{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
}

.Text-Center-Agregar-Cuenta{
    text-align: center;
    text-decoration: underline;
    text-decoration-color: yellow;
    user-select: none;
}

.Input-Editar .ant-select-selector{
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
}
.Input-Editar .ant-select-selector .ant-select-selection-search{
    display: flex !important;
    align-items: center !important;
}

.Text-Center-Agregar-Pais{
    text-align: center;
    text-decoration: underline;
    text-decoration-color: yellow;
    user-select: none;
}

.Circulo{
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
}

.Circulo-Activo{
    background-color: #1EEC41 !important;
}

.Circulo-Desactivo{
    background-color: #FF3742;
}

.Spin-Usuarios{
    margin-top: 10%;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    top: 50%;
}

.Contenedor-Botones-Agregar{
    display: flex;
    justify-content: flex-end;
    margin: 0px 6px 6px 6px;
}

.Boton-Agregar{
    margin: 2px;
    background: #1890ff !important;
    border-color: #1890ff !important;
    color: white !important;
}

.Contenedor-Principal-Cuentas{
    padding: 80px 40px 0px 40px;
    margin-top: 10px;
}

.Cuenta-Imagen{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.Contenedor-Principal-Cuentas table th{
    background-color: #000000 !important;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    padding: 6px !important;
}

.Contenedor-Principal-Cuentas table th:first-child{
    border-top-left-radius: 8px !important;
}
.Contenedor-Principal-Cuentas table th:last-child{
    border-top-right-radius: 8px !important;
}

.Contenedor-Principal-Cuentas .ant-table-pagination-right{
    display: none;
}

.Boton-Accion{
    margin: 0 1px;
    border-color:#404040 !important;
}
.Icono-Accion{
    color: #404040 !important;
}
.Boton-Accion-Editar{
    margin: 0 1px;
    border-color:#007FFF !important;
}

.Icono-Accion-Editar{
    color: #007FFF !important;
}
.Icono-Accion-Eliminar{
    color: #FF3742 !important;
}

.Boton-Accion-Eliminar:hover{
    border-color: #FF3742 !important;
}
.Boton-Accion-Eliminar{
    margin: 0 1px;
    border-color: #FF3742 !important;
}