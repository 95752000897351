#Contenedor-Registro{
    margin: auto auto;
    padding: 20px 10px;
    border-radius: 5px;
}

.Contenedor-Centrado{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Imagen-Pais{
    width: auto !important;
    height: 30px !important;
}

.Text-Center-Editar-Usuario{
    text-align: center;
    text-decoration: underline;
    text-decoration-color: yellow;
    user-select: none;
}

.Contenedor-Usuarios-Administrador{
    justify-content: center;
    padding: 80px 40px 0 40px;
    margin-top: 10px;
}

.Select-Tipo-Usuario .ant-select-selector{
    height: 40px !important;
    align-items: center;
}

.Input-Imagen-Usuario .ant-upload-list-item-list-type-picture{
    margin-top: 0 !important;
}

.Boton-Eliminar-Usuario{
    color: red !important;
}

.Fila-Filtro-Agregar{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.Celda-Usuario-Title{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Celda-Title-Usuario-Administrador{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


#Contenedor-Registro-Select{
    display: flex;
    flex-direction: row;
}

.editable-row:hover td {
    background: rgba(230, 230, 222, 0.604) !important;
    border-top: 1px solid rgb(192, 191, 191) !important;
    border-bottom: 1px solid rgb(192, 191, 191) !important;
}

.Contenedor-Usuarios-Administrador table th{
    background-color: #000000 !important;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    padding: 6px !important;
}

.Contenedor-Usuarios-Administrador table th:first-child{
    border-top-left-radius: 8px !important;
}
.Contenedor-Usuarios-Administrador table th:last-child{
    border-top-right-radius: 8px !important;
}

.ant-table-row {
    position: relative;
    cursor: pointer;
}

.ant-modal-content {
    border-radius: 5px !important;
}

.ant-table-row:hover .Contenedor-Boton{
    visibility: visible !important;
    display: block;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    background-color: white !important;
    border-radius: 50px 0px 0px 50px;
}

.Contenedor-Boton{
    position: absolute;
    visibility: hidden !important;
    width: 0;
    display: flex;
    align-items: center;
}


.Boton-Eliminar{
    position: absolute;
    right: 30%;
    top: 35%;
    font-size: 140%;
    color: red !important;
}

.Boton-Editar{
    position: absolute;
    right: 60%;
    top: 35%;
    font-size: 140%;
}

.Boton-Accion-Tipo-Usu{
    position: relative;
    font-size: 140%;
    margin: 2px 7px;
}

.Boton-Tipo-Eliminar{
    color: red !important;
}

.Form-Item{
    margin: 2px !important;
}

.Text-Center{
    text-align: center;
    margin-bottom: 10px;
}

.Input-Editar{
    width: 100% !important;
    margin: 3px !important;
}

.Input-Editar-Password{
    margin: 9px 8px !important;
}

.ant-table-row .ant-table-cell:hover .Fila-Paises-Usuarios{
    display: flex;
}

.ant-table-row .ant-table-cell .Celda-Enviando{
    display: flex;
}


.ant-table-row .ant-table-cell:hover .Fila-Paises-Usuarios div{
    display: flex;
}

.Contenedor-Fila-Paises-Usuario{
    height: 45px;
    background-color: white;
    box-shadow: 6px 10px 12px -3px rgba(0, 0, 0, 0.75);
    border-radius: 15px;
    padding: 5px;
}

.Contenedor-Imagen-Cuenta-Pais{
    display: flex;
    position: relative;
    align-content: center;
    width: 50px;
}

.Imagen-Avatar-Usuario{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.ant-popover-title{
    text-align: center;
}

.ant-popover-inner{
    border-radius: 10px !important;
}

.Fila-Paises-Usuarios{
    display: none;
    position: absolute;
    top: 40px;
    width: 65px;
    z-index: 10;
    margin-left: 20px !important;
    margin-right: 10px !important;
}

.Contenedor-Boton-Registro-Usuario{
    margin-top: 25px !important;
    text-align: center;
    margin-bottom: 0;
}

.Tag-Cuenta-Usuario-Registro{
    padding: 5px 5px 5px !important;
    font-size: 1em !important;
    margin: 2px 0 2px 6px !important;
    align-items: center;
    margin: 4px !important;
}

.ant-table-row{
    height: 50px !important;
}

.Imagen-Pais-Usuario{

    /* position: absolute; */
    /* left: 0; */
    width: 32px;
    height: 32px;
    border-radius: 50px 50px 50px 50px;
}

.Imagen-Cuenta-Usuario{
    position: absolute;
    clip: rect(0px,60px,200px,13px) !important;
    /* margin: 0 8px; */
    /* right: 6px; */
    left: 6px;
    width: auto;
    height: 32px;
    border-radius: 0px 50px 50px 0px;
}

.Tabla-Usuarios{
    padding: 10px;
    margin: 20px;
    user-select: none;
    cursor: pointer;
}

.Spin-Usuarios{
    margin-top: 10%;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    top: 50%;
}

.Circulo{
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
}

.Circulo-Activo{
    background-color: #1EEC41 !important;
}

.Contenedor-Usuarios-Administrador .ant-table-pagination-right{
    display: none;
}

.Circulo-Desactivo{
    background-color: #FF3742;
}

.Contenedor-Usuarios{
    padding-right: 40px;
    padding-left: 40px;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
}


@media (max-width: 750px) {
    #Contenedor-Registro-Select{
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 750px) {
    #Contenedor-Registro{
        display: flex;
        flex-direction: column;
    }
}