.Contenedor-Principal-Carga-Archivos{
    padding: 80px 40px 0px 40px;
    margin-top: 10px;
}

.Titulo-Sector{
    margin: 0 8px !important;
}

.Select-Bucket-Input .ant-select-selection-item{
    background: #ffffff !important;
    border-color: #ffffff !important;
}

.Fila-Rango-No-Disponible-Editar-Sub-Tipo{
    display: flex;
    align-items: center;
    margin: 2px 0;
}

.Boton-Agregar-Rango-Editar-Sub-Tipo{
    width: 100%;
}

.Select-Bucket-Input .ant-select-disabled .ant-select-selection-item{
    border-color: #f5f5f5 !important;
    background: #f5f5f5 !important;
}
.Col-Form-Rango-Horarios{
    margin-left: 0 !important;
}

.Restringir-Carga-Carga-Archivos{
    border-radius: 5px;
    margin: 8px;
    /* box-shadow: 1px 1px 1px 1px gray; */
    box-shadow: 3px 3px 3px -3px rgba(0,0,0,0.8);
}

.Boton-Actualizar{
    background-color: #4096ff;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 5px;
}

.Modal-Notificacion .ant-modal-content{
    border-radius: 20px !important;
}
.Modal-Notificacion .ant-modal-body{
    padding: 0 !important;
}


.Boton-Cargando-Archivos-Python{
    border-radius: 5px !important;
}

.Contenedor-Fila-Canales{
    display: flex;
    justify-content: end;
}

.Icono-Input-Buscador{
    margin: 0 5px;
}

.Input-Buscador-Carga-Archivos{
    border-radius: 5px !important;
    height: 45px !important;
}

.Select-Filtro-Carga-Archivos{
    width: 150px !important;
    margin: 3px 3px !important;
}

.Icono-Actualizar{
    font-size: 150% !important;
    color: white !important;
}

.Boton-Crear-Carga-Archivos{
    background: #1890ff !important;
    border-color: #1890ff !important;
    color: white !important;
}

.Input-Usuario{
    margin: 8px 8px !important;
}

.Contenedor-Correos p:last-child{

    margin-bottom: 0;
}

.ant-tag{
    display: flex !important;
    justify-content: space-between !important;
}

.ant-tag > .anticon + span, .ant-tag > span + .anticon {
    display: flex;
    align-items: center;
    padding: 5px;
}

.Contenedor-Tag-Carga-Archivos{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-right: 6px;
    height: 150px;
    overflow-y: scroll;
    padding-right: 4px;
}

.Contenedor-Tag-Carga-Archivos::-webkit-scrollbar {
    width: 10px;
    height: 8px;
}

.Contenedor-Tag-Carga-Archivos::-webkit-scrollbar-thumb  {
    border-radius: 2px;
    background: #bae7ff;
}

.Tag-Correo-Carga-Archivos{
    border-radius: 5px !important;
    margin: 4px 0 !important;
    padding: 4px !important;
    font-size: 16px !important;
    right: 0 !important;
}

.Input-Usuario Input{
    height: 40px;
}

.Input-Usuario-Dias{
    margin: 8px 8px !important;
}

.Titulo-Principal-Carga-Archivos{
    display: flex;
    justify-content: center;
    margin-bottom: 0;
}

.Contenedor-Filtro-Carga-Archivos{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Input-Editar{
    width: 100% !important;
    margin: 3px !important;
}

.Spin-Carga{
    margin-top: 10%;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    top: 50%;
}

.Botones-Acciones-Editar-Canal{
    margin: 3px 3px;
}

.Boton-Accion-Editar-Canal{
    position: relative;
    font-size: 140%;
    margin: 2px 7px;
}

.Text-Center-Carga-Archivos{
    text-align: center;
    text-decoration: underline;
    text-decoration-color: yellow;
    user-select: none;
}

.Circulo{
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
}

.Circulo-Activo{
    background-color: #1EEC41 !important;
}

.Circulo-Desactivo{
    background-color: #FF3742 !important;
}

.Fila-Buscador-Boton-Carga-Archivos{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Contenedor-Carga-Archivos-Tabla, .Contenedor-Carga-Archivos-Tabla-Canales {
    margin-bottom: 30px;
}

.Contenedor-Carga-Archivos-Tabla .ant-table-pagination-right{
    display: none;
}
.Contenedor-Carga-Archivos-Tabla-Canales .ant-table-pagination-right{
    display: none;
}

.Contenedor-Carga-Archivos-Tabla table th{
    background-color: #000000 !important;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    padding: 6px !important;
}
.Contenedor-Carga-Archivos-Tabla-Canales table th{
    background-color: #000000 !important;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    padding: 6px !important;
}

.Contenedor-Carga-Archivos-Tabla table th:first-child{
    border-top-left-radius: 8px !important;
}
.Contenedor-Carga-Archivos-Tabla-Canales table th:first-child{
    border-top-left-radius: 8px !important;
}
.Contenedor-Carga-Archivos-Tabla-Canales table th:last-child{
    border-top-right-radius: 8px !important;
}

.Contenedor-Acciones-Iconos{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Boton-Accion{
    margin: 0 1px;
}

.Boton-Accion-Eliminar:hover{
    border-color: #FF3742 !important;
}
.Icono-Accion{
    color: black !important;
}
.Icono-Accion-Eliminar{
    color: #FF3742 !important;
}