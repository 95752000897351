.Contenedor-Titulo-Carga-Archivo{
    width: 100%;
    height: 45px;
    background: #F4F4F4;
    display: flex;
    align-items: center;
    padding-left: 60px;
}


.Contenedor-Tabla-Carga-Archivos table th{
    background-color: #000000 !important;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    padding: 14px !important;
}

.Contenedor-Tabla-Carga-Archivos table th:first-child{
    border-top-left-radius: 8px !important;
}

.Contenedor-Tabla-Carga-Archivos table th:last-child{
    border-top-right-radius: 8px !important;
}

#Contenedor-Filtro-Carga-Archivo{
    margin-left: 60px;
    margin-top: 20px;
    display: flex;
}

.Modal-Confirmacion-Carga-Archivo .ant-modal-content{
    border-radius: 10px !important;
    width: 350px;
}

.Modal-Confirmacion-Carga-Archivo textarea{
    overflow: auto;
    outline: none;

}

.Btn-Desactivado-Carga-Archivo{
    background: #C4C4C4 !important;
}

.Input-Buscar-Dt-Carga-Archivos{
    width: 85%;
    height: 36px;
    border: 1px solid #A4A3A3;
    border-radius: 27px;
    text-align: left;
}

.Input-Buscar-Dt-Carga-Archivos input{
    border: 0;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    width: 95%;
}

.Input-Buscar-Dt-Carga-Archivos input:focus{
    border: 0;
    outline: none;
}

.Modal-Dts-Carga-Archivos .ant-modal-content{
    border-radius: 8px;
}

.Celda-Tabla-Carga-Archivo{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Btn-Enviando-Archivo-Tabla-Carga-Archivo{
    background: #52C41A !important;
    border: 1px solid #52C41A !important;
}