/* C4C4C4 -> PLOMO */
/* 4D4D4D -> PLOMOOSCURO */
/* FFFFFF -> BLANCO */

.W700-S20-H27-CPlomo{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #C4C4C4;
}

.W400-S16-H21-CPlomo{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #C4C4C4;
}

.W700-S16-H21-CBlanco{
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    text-align: center !important;
    color: #FFFFFF !important;
}

.W700-S20-H27-CPlomoOscuro{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #4D4D4D;
}

.W600-S15-H20-C787878{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #787878;
}

.W600-S14-H19-C000000{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.015em;
    color: #000000;
}

.W700-S26-H35-C000000{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: -0.015em;
    color: #000000;
}

.W700-S14-CFFFFFF{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
}

.W600-S12-H16-C787878{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #787878;
}

.W600-S12-H16-C1E1E1E{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #1E1E1E;
}

.W400-S12-H16-C1E1E1E{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #1E1E1E;
}

.W400-S11-H16-C1E1E1E-Red{
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 16px !important;
    color: #a020f0 !important;
}

.W400-S11-H16-C1E1E1E{
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 16px !important;
    color: #1E1E1E !important;
}

.W400-S8-H16-C1E1E1E{
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 8px !important;
    line-height: 16px !important;
    color: #1E1E1E !important;
}

.W600-S12-H16-CFFFFFF-l0015{
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: -0.015em !important;
    color: #FFFFFF !important;
}

.W600-S12-H16-CC4C4C4-L0015{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #C4C4C4;

}

.W600-S12-H16-C1876F2{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #1876F2;
}

.W600-S15-H20-C787878{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.015em;
    color: #787878;
}