.Circulo-Representativo-Status{
    width: 14px;
    height: 14px;
    background: #F75555;
    border-radius: 50px;
    margin-left: 5px;
    margin-top: 2px;
}

.Icono-Lupa-Status{
    width: 13px;
    margin-right: 5px;
    margin-top: -3px;
    cursor: pointer;
}

.Fila-Detalle-Status:hover .Txt-Staus-Detalle-Status{
    display: none;
}

.Fila-Detalle-Status:hover .Contenedor-Iconos-Detalle-Status{
    display: flex;
}

.Contenedor-Iconos-Detalle-Status{
    display: none;
}