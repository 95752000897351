.Contenedor-Centrado{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Contenedor-Permisos-Tipos{
    padding: 80px 40px 0 40px;
    margin-top: 10px;
}

.Celda-Tabla-Permisos{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.Circulo{
    width: 15px;
    height: 15px;
    border-radius: 50% !important;
}

.Circulo-Activo{
    background-color: #1EEC41 !important;
}

.Circulo-Desactivo{
    background-color: #FF3742 !important;
}

.Contenedor-Fila-Agregar{
    display: flex;
    justify-content: space-between;
}

.Boton-Agregar{
    margin: 2px;
    background: #1890ff !important;
    border-color: #1890ff !important;
    color: white !important;
}

.Boton-Accion{
    margin: 0 1px !important;
}

.Boton-Accion-Editar{
    margin: 0 1px !important;
    border-color:#007FFF !important;
}

.Icono-Accion-Editar{
    color: #007FFF !important;
}

.Icono-Accion{
    color: black !important;
}
.Icono-Accion-Eliminar{
    color: #FF3742 !important;
}

.Boton-Accion-Eliminar:hover{
    border-color: #FF3742 !important;
}

.Contenedor-Permisos-Tipos table th{
    background-color: #000000 !important;
    font-family: 'Segoe UI' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    color: #FFFFFF !important;
    padding: 6px !important;
}

.Contenedor-Permisos-Tipos .ant-table-pagination-right{
    display: none;
}

.Contenedor-Permisos-Tipos table th:first-child{
    border-top-left-radius: 8px !important;
}

.Contenedor-Permisos-Tipos table th:last-child{
    border-top-right-radius: 8px !important;
}

.Tabla-Tipos-Permisos{
    margin-top: 20px;
}