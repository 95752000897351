.Text-Center-Permisos{
    text-align: center;
    text-decoration: underline;
    text-decoration-color: yellow;
    user-select: none;
}

.Contenedor-Permiso-Collapse{
    margin: 5px 0 !important;
}

.Collapse-Oculto-Permisos{
    display: none;
}

.Contenedor-Permiso-Collapse .ant-collapse-content > .ant-collapse-content-box{
    padding: 0 16px;
}

.Contenedor-Permiso-Collapse .ant-collapse-header{
    display: flex !important;
    align-items: center !important;
}

.Contenedor-Permiso-Collapse .ant-collapse-expand-icon{
    display: flex;
    align-items: center;
}

.Input-Buscador-Permisos-Tipo-Usuario{
    border-radius: 5px !important;
    height: 45px !important;
}

.Contenedor-Fila-Acciones{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.Texto-Descripcion-Permiso{
    margin-bottom: 0;
    padding: 0 3px;
}

.Contenedor-Centrado{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Circulo-Activo{
    background-color: #1EEC41 !important;
}

.Circulo-Desactivo{
    background-color: #FF3742 !important;
}

.ant-modal-content {
    border-radius: 5px !important;
}

.Input-Usuario{
    margin: 8px 8px !important;
}
.Input-Usuario .ant-select-selector{
    height: 40px !important;
    display: flex;
    align-items: center;
}
.Input-Usuario .ant-select-selector .ant-select-selection-search{
    display: flex;
    align-items: center;
}

.Input-Editar{
    width: 100% !important;
    margin: 3px !important;
}

.Contenedor-Permisos{
    margin-top: 10px;
    padding-top: 80px;
}

.Spin-Permisos{
    margin-top: 10%;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    top: 50%;
}

.Text-Center{
    text-align: center;
}

.Tipo-Permiso-Header{
    background-color: rgb(193, 189, 189);
    width: 900px !important;
    padding: 10px;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.Tipo-Permiso-Contenedor-Oculto{
    transition: opacity 1s ease-out;
    display: none;
}
.Tipo-Permiso-Contenedor{
    display: block;
    padding: 10px 0px 10px 10px;
}
.Tipo-Permiso-Fila{
    display: flex;
    justify-content: space-between;
    margin: 10px 10px;
}