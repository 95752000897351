*{
    margin: 0;
}

#Img-Logo-App{
    width:100%;
    height:100vh;
    background: #F2F2F2;
    position: absolute;
    display: flex;
    align-items: center;
    place-content: center;
    z-index: 10000;
}