.Contenedor-Seleccionar-Negocio{
    height: 100%;
    top: 0px;
    position: absolute;
    width: 100%;
    background-color: white;
    /* opacity: 0; */
    z-index: 1;
}

.Logo-Seleccionar-Negocio{
    width: 120px;
    height: 120px;
}

.Card-Negocio-Seleccionar-Negocio{
    width: 191px;
    height: 179px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px #C4C4C4;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.Contenedor-Seleccionar-Negocio .ant-skeleton-button{
    width: 191px !important;
    height: 179px !important;
}


.Animacion-Desaparecer-Card-Negocio-Seleccionado{
    animation: animacion-desaparecer-negocio-seleccionado 2s ease-in-out 
}

@keyframes animacion-desaparecer-negocio-seleccionado {
    0% {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }
    100% {
      transform: scale3d(1.5, 1.5, 1);
      opacity: 0;
    }
}