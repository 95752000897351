#Contenedor-Login{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

#Contenedor-Formulario-Login input{
    border-radius: 8px;
}

#Contenedor-Formulario-Login .ant-input-password{
    border-radius: 8px;
}

.Titulo-Login{
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 20px;
}

.Btn-Login{
    background: #C4C4C4 !important;
    border-radius: 43px;
    border: 1px solid #C4C4C4 !important;
    width: 100%;
    height: 50px !important;
}

.Btn-Login:hover{
    background-color: #787878 !important;
}