#Contenedor-Top{
    width: 100%;
    height: 76px;
    background: #FFFF00;
    position: fixed;
    top: 0;
    z-index: 5;
}

.Icono-Status-Top{
    width: 20px;
    /* margin-right: 8px; */
}

.Icono-Carga-Archivos-Top{
    width: 22px;
    /* margin-right: 8px; */
}

.Nombre-Modulo-Top{
    background-color: red;
    /* height: 200px; */
    position: relative;
}

.Nombre-Modulo-Top:hover .Contenedor-SubModulos-Top{
    display: block;
}

.Contenedor-SubModulos-Top{
    /* display: none; */
    /* position: absolute; */
    border-radius: 8px;
    background-color: white;
    width: 100%;
    /* margin-top: 15px; */
    box-shadow: 0px 4px 4px #c4c4c4;
    padding-bottom: 10px;
}

.Fila-SubModulo-Top{
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 10px;
}

.Fila-SubModulo-Top:hover{
    background-color: #c4c4c4;
}